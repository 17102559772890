<template>
  <div>
    <div class="mb-4 d-flex justify-content-between">
      <div class="">
        <h4 class="">{{ potensiData.name }}</h4>
      </div>
      <div v-if="store.state.user.role === 'admin'" class="">
        <a-button v-if="!isEdit" @click.prevent="clickEdit" type="default">
          <edit-outlined></edit-outlined>
          Edit</a-button
        >
        <template v-else>
          <a-button class="text-success" @click.prevent="saveEdit" type="link">Simpan</a-button>
          <a-divider type="vertical" class="mx-1"></a-divider>
          <a-button class="text-danger" @click.prevent="() => (isEdit = false)" type="link"
            >Batal</a-button
          >
        </template>
      </div>
    </div>
    <div>
      <a-form :model="potensiData" layout="vertical" hide-required-mark>
        <a-row :gutter="[32, 0]">
          <a-col :md="24" :lg="12">
            <a-form-item v-bind="validateInfos.name">
              <template #label>
                <label for="NamaPotensi" class="font-weight-bold mb-0">Nama</label>
              </template>
              <a-textarea v-if="isEdit" v-model:value="potensiData.name" :max-length="90" />
              <p class="my-0" v-else>{{ potensiData.name }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Alamat" class="font-weight-bold mb-0">Alamat</label>
              </template>
              <a-textarea v-if="isEdit" :rows="6" v-model:value="potensiData.address"></a-textarea>
              <p class="my-0" v-else>{{ potensiData.address }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item name="vendorId" v-bind="validateInfos.vendorId">
              <template #label>
                <label for="Telepomn" class="font-weight-bold mb-0">Telepon</label>
              </template>
              <a-textarea v-if="isEdit" :max-length="16" v-model:value="potensiData.phone" />
              <p class="my-0" v-else>{{ potensiData.phone }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item name="categoryId" v-bind="validateInfos.categoryId">
              <template #label>
                <label for="Kategori" class="font-weight-bold mb-0">Provinsi</label>
              </template>
              <a-textarea v-if="isEdit" :max-length="32" v-model:value="potensiData.province" />
              <p class="my-0" v-else>{{ potensiData.province }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Kota" class="font-weight-bold mb-0">Kota/Kabupaten</label>
              </template>
              <a-textarea v-if="isEdit" :max-length="32" v-model:value="potensiData.city" />
              <!-- <p class="my-0" v-else>Jakarta Selatan</p> -->
              <p class="my-0" v-else>{{ potensiData.city }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="KodePos" class="font-weight-bold mb-0">Kode Pos</label>
              </template>
              <a-textarea v-if="isEdit" :max-length="5" :default-value="potensiData.kode_pos" />
              <p class="my-0" v-else>{{ potensiData.kode_pos }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="KanCa" class="font-weight-bold mb-0">Kantor Cabang</label>
              </template>
              <a-auto-complete
                style="width: 100% !important"
                v-if="isEdit"
                v-model:value="potensiData.KC.name"
                :options="kanwilDataFiltered"
                @search="handleKanwilSearch"
                @select="onSelectKanwil"
                placeholder="input here"
              />
              <!-- <a-select v-if="isEdit" class="ml-2" style="width: 100%" v-model:value="potensiData.KC">
                <a-select-option :value="potensiData.KC">{{potensiData.KC.name}}</a-select-option>
                <a-select-option v-for="(kanwil, i) in kanwilData" :key="i" :value="kanwil._id">{{kanwil.name}}</a-select-option>
              </a-select> -->
              <p class="my-0" v-else>{{ potensiData.KC?.name }}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Kelas Banner" class="font-weight-bold mb-0">Nama (Input Excel)</label>
              </template>
              <p class="my-0">{{ potensiData.queryData }}</p>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch, createVNode, computed } from 'vue'
import { useStore } from 'vuex'
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import { Modal, notification } from 'ant-design-vue'
import { getOne, update, getAllKanwil, getAllKC } from '@/services/axios/brivice-backend'
import { useForm } from '@ant-design-vue/use'
import { useRoute, useRouter } from 'vue-router'
import fuzzysort from 'fuzzysort'

export default {
  components: { EditOutlined },
  props: {
    potensi: {
      type: Object,
      default: () => {
        return {}
      },
    },
    vendors: {
      type: Array,
      default: () => {
        return []
      },
    },
    categories: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['fetch'],
  setup(props, { emit }) {
    const { course: courseData } = toRefs(props)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const dataPotensi = ref([])
    let kanwilData = ref([])
    let kanwilDataFiltered = ref([])
    const idCourse = route.params.id
    const { vendors, categories, potensi: potensi } = toRefs(props)
    console.log(potensi.value, 'pot')
    let potensiData = ref({
      name: potensi.value.name,
      description: null,
      address: null,
      phone: null,
      KC: null,
      price: 0,
      type: 'free',
      vendorId: null,
      province: null,
      city: null,
      categoryId: null,
      isFeatured: 'no',
      isBanner: 'no',
      statusPublication: null,
      queryData: null,
    })

    const onSelectKanwil = value => {
      const result = kanwilData.value.find(x => x.value === value)
      potensiData.value.KC = result
      // console.log('onSelect', result);
    }

    let handleKanwilSearch = value => {
      // value ? kanwilDataFiltered.value.filter((x) => x.value === value) : kanwilDataFiltered.value = kanwilData.value
      if (value !== '') {
        const results = fuzzysort.go(value, kanwilData.value, { key: 'value' })
        const sortData = results.map(result => {
          return result.obj
        })
        kanwilDataFiltered.value = sortData
        // loadingTable.value = false
      } else {
        kanwilDataFiltered.value = kanwilData.value
        // loadingTable.value = false
        // fetchCourse()
      }
      // kanwilDataFiltered.value
      // kanwilData.value = !value
      //   ? []
      //   : [{ value }, { value: value + value }, { value: value + value + value }];
    }
    let categoryName = computed(() => {
      if ('category' in courseData.value) {
        if (categories.value.length)
          return categories.value.find(cat => cat._id === courseData.value.category._id).name
        return '-'
      }

      return '-'
    })
    let vendorName = computed(() => {
      if ('vendor' in courseData.value) {
        if (vendors.value.length)
          return vendors.value.find(vendor => vendor._id === courseData.value.vendor._id).name
        return '-'
      }
      return '-'
    })

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
      categoryId: [
        {
          required: true,
          message: 'Kategori harus diisi!',
          trigger: 'change',
        },
      ],
      vendorId: [
        {
          required: true,
          message: 'Vendor harus diisi!',
          trigger: 'change',
        },
      ],
    })

    const fetchDetail = async () => {
      const data = await getOne(route.params.id)
      const kanwils = await getAllKC()
      kanwilData.value = kanwils.result
      dataPotensi.value = data.result
      console.log(dataPotensi.value, 'detail general')
      kanwilDataFiltered.value = kanwilData.value
      for (const kanwil of kanwilData.value) {
        kanwil.value = kanwil.name
      }
      potensiData.value = {
        name: data.result.name,
        address: data.result.address,
        phone: data.result.phone,
        KC: data.result.closestKC,
        kode_pos: data.result.kode_pos,
        province: data.result.province,
        city: data.result.city,
        queryData: data.result.queryName,
      }
    }

    const clickEdit = async () => {
      isEdit.value = true
    }

    const { resetFields, validate, validateInfos } = useForm(potensiData, rulesRef)

    const saveEdit = async () => {
      try {
        const { value: form } = potensiData
        const payload = {
          _id: route.params.id,
          ...form,
          closestKC: form.KC,
          accesstoken: localStorage.getItem('access_token'),
        }
        // await validate()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada potensi?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              // const data = await store.dispatch('course/UPDATE_COURSE', payload)
              const data = await update(payload)
              console.log(data, 'update')
              isEdit.value = false

              notification.success({
                message: `Berhasil Mengubah Data Pada Potensi`,
              })

              router.push('/potensi')
              // emit('fetchCourse')
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    let isEdit = ref(false)

    onMounted(async () => {
      fetchDetail()
    })

    return {
      potensiData,
      isEdit,
      kanwilData,
      kanwilDataFiltered,
      store,
      courseData,
      fetchDetail,
      clickEdit,
      saveEdit,
      rupiahPrice,
      onSelectKanwil,
      validateInfos,
      rulesRef,
      handleKanwilSearch,
      categoryName,
      vendorName,
    }
  },
}
</script>

<style></style>
